function sideNav() {
    this.elements = {
      nav: $("#mySidenav"),
      navItems: $(".sidenav-item"),
      navToggle: $("#sideNavToggle"),
      unloggedContact: $("#UnloggedContact"),
      contactTooltip: $("#ContactTooltipContainer .tooltipjs")
    };
    this.animation = function(options, mode) {
      var elements = this.elements;

      var open = {};
      open.start = function() {
        elements.navToggle.addClass('d-none');
      }
      open.complete = function() {
        elements.navItems.each(function() { $(this).removeClass('d-none'); });
        elements.unloggedContact.removeClass('d-none');
      }

      var close = {};
      close.start = function() {
        elements.navItems.each(function() { $(this).addClass('d-none'); });
        elements.unloggedContact.addClass('d-none');
      }
      close.complete = function() {
        elements.navToggle.removeClass('d-none');
      }

      var elementAnimate = function(animationMode) {
        elements.nav.animate(
          options.parameters,
          (animationMode) ? open : close,
          options.duration,
          options.type
        );
      }

      var fnOpen = function() {
        elementAnimate(true);
      }

      var fnClose = function() {
        var ect = elements.contactTooltip;
        var ectDisplay = ect.css('display');
        if (ectDisplay !== 'none' || ectDisplay === undefined) {
          ect.css('display','none');
        }
        elementAnimate(false);
      }

      if (mode) { fnOpen(); } else { fnClose(); }
    }
}

function menuToggle(mode) {
  var navControl = new sideNav();
  if (mode) {
    navControl.animation({
      parameters: { width: '250px' },
      duration: 3750,
      type: 'linear'
    }, mode);
  } else {
    navControl.animation({
      parameters: { width: '0px' },
      duration: 3750,
      type: 'linear'
    }, mode);
  }
}
